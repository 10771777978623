<template>
  <!-- Traitement phytosanitaire -->
  <div class="fiche-pesees-phyto-edit-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Fiche de pesées</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn
            color="primary"
            hollow
            text="Paramètre de la fiche"
            @click="openModal('params', parameters)"
          />
          <Btn
            color="primary"
            hollow
            text="Exporter la fiche de pesées"
            @click="download('xls', 'evenement', $route.params.tid)"
          />
        </div>
        <form>
          <Table
            :headers="headers"
            :static-headers="staticHeaders"
            :items="formattedModalities"
            :hiddenItems="['id', 'ordre', 'row_error', 'dose_epandue_unite']"
            :tools="true"
            @table-row-validate="validate($event)"
          >
            <template v-slot:tools="{ item }">
              <Btn
                @click="openModal('edit', item.item)"
                icon="create"
                color="white"
                title="Modifier la modalité"
              />
            </template>

          </Table>
        </form>
      </Container>

    </Section>

  </div>

  <!-- Modale pour une modalité -->
  <Modal
    type="global"
    title="Edition d'une modalité"
    :active="modal.edit"
    :data="modalData"
    @modal-close="modal.edit = false"
    :containForm="true"
    :modalValidationSchema="modaliteSchema"
    @modal-form-submitted="onSubmit(modalData.id, $event)"
  >
    <template v-slot:modal-body="{ data }">
      <Input
        label="Densité"
        id="densite"
        type="number"
        :options="{ min: 0, step: 0.001 }"
      />

      <Input
        label="Pesée réelle"
        id="pesee_reelle"
        type="number"
        :options="{ min: 0, step: 0.001 }"
        :inputAfter="data?.suffixe"
      />

      <Input label="Observations" id="observations" type="textarea"/>

    </template>

    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.edit = false" />
      <Btn text="Enregistrer" color="primary" type="submit" />
    </template>

  </Modal>

  <!-- Modale des paramétrages de la fiche de pesées -->
  <Modal
    type="parameters"
    v-if="parameters"
    :title="evenement_nom ?
    `Paramétrage de la fiche de pesées : ${evenement_nom}` :
    `Paramétrage de la fiche de pesées`"
    :active="modal.params"
    :data="modalData"
    @modal-close="modal.params = false"
    :containForm="true"
    :modalValidationSchema="parametersSchema"
    @modal-form-submitted="setParameters($event)"
  >
    <template v-slot:modal-body>
      <Input
        label="Marge de sécurité calcul"
        type="number"
        input-after="%"
        :options="{ step: 0.001, min: 0, max: 100 }"
        id="marge_securite_calcul"
      />

      <Input
        label="Tolérance écart pesée"
        type="number"
        input-after="%"
        :options="{ step: 0.001, min: 0, max: 100 }"
        id="tolerance_ecart_pesee"
      />

      <Input
        label="Pulvérisation"
        type="number"
        input-after="l/ha"
        :options="{ step: 0.001, min: 0}"
        id="pulverisation"
      />

      <Input
        label="Volume bouillie/surface"
        type="number"
        input-after="l"
        :options="{ step: 0.001, min: 0}"
        id="volume_bouillie_surface"
      />

      <Radio
        v-if="unites"
        label="Unité de calcul quantités"
        id="unite_uid"
        :items="unites"
        inputStyle="inline"
      />

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.params = false" />
      <Btn text="Enregistrer" color="primary" type="submit" />
    </template>

  </Modal>

  <Loader :active="loading" />

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'FichePeseesPhytoSubview',
  components: {
    Btn,
    Table,
    Container,
    Input,
    Modal,
    Radio,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const parametersSchema = this.yup.object().shape({
      marge_securite_calcul: this.yup.string().nullable(),
      unite_uid: this.yup.object().nullable(),
      volume_bouillie_surface: this.yup.string().nullable(),
      pulverisation: this.yup.string().nullable(),
      tolerance_ecart_pesee: this.yup.string().nullable(),
    })

    const modaliteSchema = this.yup.object().shape({
      densite: this.yup.string().nullable(),
      pesee_reelle: this.yup.string().nullable(),
      observations: this.yup.string().nullable(),
    })

    return {
      parametersSchema,
      modaliteSchema,
      modal: {
        params: false,
        edit: false,
      },
      modalData: null,
      loading: true,
      headers: [],
      staticHeaders: [
        'Produit',
        'Formulation',
        'Dose <br>épandue',
        'Densité',
        'Nb rép.',
        'Surface <br>application',
        'Vol (pds) <br>/ surface',
        'Quant. <br>calculée',
        'Pesée <br>théorique <br>mini',
        'Pesée <br>réelle',
        'Pesée <br>théorique <br>maxi',
        'Réalisé <br>par',
        'Réalisé <br>le',
        'Observations',
        'Validation',
      ],
      modalities: {},
      formattedModalities: [],
      fiche_pesee_id: null,
      evenement_nom: null,
      unites: [],
      unites_dose_epandue: [],
      parameters: null,
      componentKey: 0,
    }
  },
  created() {
    this.loading = true
    this.setComponent()
  },

  methods: {
    validate(event) {
      const values = { ...event }
      // eslint-disable-next-line
      values.trigramme = (this.$store.state.auth.user.data.prenom.substring(0, 2) + this.$store.state.auth.user.data.nom.substring(0, 1)).toUpperCase()
      values.date = this.helperService.formatDateForApi(new Date())
      this.onSubmit(values.id, values)
    },

    openModal(modal, data) {
      this.modalData = data
      if (modal === 'edit') {
        this.modalData = this.getMods(data)
      }
      this.modal[modal] = true
    },

    setComponent() {
      this.emitter.emit('open-loader')
      this.getModalites().then((rm) => {
        this.modalities = rm
        this.getFormatModalities(this.modalities).then((rfm) => {
          this.formattedModalities = rfm
          this.setFicheParams()
          this.emitter.emit('close-loader')
        })
      })
    },

    setFicheParams() {
      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/phytosanitaire/pesee/${this.fiche_pesee_id}`
      this.fetchService.get(endpoint).then((res) => {
        const datas = res.data
        datas.unite_uid = datas.unite?.uid
        if (!datas.marge_securite_calcul) {
          datas.marge_securite_calcul = this.parseParameterValue('PHYTO_PESEE_MARGESECURITECALCUL')
        }

        if (!datas.tolerance_ecart_pesee) {
          datas.tolerance_ecart_pesee = this.parseParameterValue('PHYTO_PESEE_TOLERANCEECARTPESEE')
        }

        if (!datas.pulverisation) {
          datas.pulverisation = this.parseParameterValue('PHYTO_PESEE_PULVERISATION')
        }

        if (!datas.volume_bouillie_surface) {
          datas.volume_bouillie_surface = this.parseParameterValue('PHYTO_PESEE_VOLUMEBOUILLIESURFACE')
        }

        this.parameters = datas
        this.getUnites()
      })
    },

    parseParameterValue(parameterName) {
      try {
        const parameterValue = JSON.parse(this.$store.getters['app/parameters']([parameterName])[0].valeur)
        // eslint-disable-next-line
        return typeof parameterValue === 'string' && parameterValue ? parameterValue : typeof parameterValue === 'object' && parameterValue ? parameterValue.key : null
      } catch (e) {
        console.log(e)
        return null
      }
    },

    getMods(modalitie) {
      return {
        id: modalitie.id,
        composant_uid: modalitie.element_reference,
        dose_epandue: this.taskService.getValueOnly(modalitie.dose_epandue),
        unite_uid: modalitie.dose_epandue_unite,
        densite: modalitie.densite,
        pesee_reelle: this.taskService.getValueOnly(modalitie.pesee_reelle),
        trigramme: modalitie.trigramme,
        date: modalitie.date || null,
        observations: modalitie.observations,
        suffixe: this.taskService.getSuffixe(modalitie.pesee_reelle),
      }
    },

    getModalites() {
      return new Promise((resolve) => {
        const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/phytosanitaire/pesee/modalite`
        this.fetchService.get(endpoint, { limit: 0 }).then((res) => {
          resolve(res.data)
        })
      })
    },

    getFormatModalities(modalities) {
      return new Promise((resolve) => {
        let datas = []
        modalities.forEach((modality) => {
          const data = {
            id: modality.id,
            ordre: modality.modalite?.ordre,
            produit: modality.produit?.designation,
            formulation: modality.produit?.formulation?.valeur,
            dose_epandue: this.taskService.concatValueUnit(
              modality.produit_phytosanitaire?.dose ?? 'NC',
              modality.produit_phytosanitaire?.unite ? modality.produit_phytosanitaire.unite.valeur : '',
            ),
            dose_epandue_unite: modality.produit_phytosanitaire?.unite
              ? modality.produit_phytosanitaire.unite.valeur
              : null,
            densite: modality.densite,
            nb_repetitions: modality.nb_repetitions,
            surface_application: this.taskService.concatValueUnit(
              modality.surface_application ?? 'NC',
              modality.surface_application ? 'm²' : '',
            ),
            volume_surface: this.taskService.concatValueUnit(
              modality.surface ? this.helperService.round(modality.surface, 3) : 'NC',
              modality.unite ? modality.unite.valeur : '',
            ),
            quantite: this.taskService.concatValueUnit(
              modality.quantite ? this.helperService.round(modality.quantite, 3) : 'NC',
              modality.quantite ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
            ),
            pesee_min: this.taskService.concatValueUnit(
              modality.pesee_min ? this.helperService.round(modality.pesee_min, 3) : 'NC',
              modality.pesee_min ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
            ),
            pesee_reelle: this.taskService.concatValueUnit(
              modality.pesee_reelle ? this.helperService.round(modality.pesee_reelle, 3) : 'NC',
              modality.pesee_reelle ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
            ),
            pesee_max: this.taskService.concatValueUnit(
              modality.pesee_max ? this.helperService.round(modality.pesee_max, 3) : 'NC',
              modality.pesee_max ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
            ),
            trigramme: modality.trigramme,
            date: modality.date?.split(' ').shift(),
            observations: modality.observations,
            validation: modality.date !== null && modality.trigramme !== null,
          }
          data.row_error = false
          this.fiche_pesee_id = modality.fiche_phytosanitaire_pesee?.id
          this.evenement_nom = modality.evenement_designation
          this.unites_dose_epandue = modality.fiche_phytosanitaire_pesee?.unite
          if (data.pesee_min && data.pesee_max && data.pesee_reelle) {
            const temp = { ...data }
            const pr = this.taskService.getValueOnly(temp.pesee_reelle)
            const pmin = this.taskService.getValueOnly(temp.pesee_min)
            const pmax = this.taskService.getValueOnly(temp.pesee_max)
            if (pr && pmin && pmax) {
              data.row_error = !((pr - pmin) >= 0
                && (pmax - pr) >= 0)
            }

            if (pmin === 'NC' || pmax === 'NC') {
              data.row_error = false
            }
          }

          datas.push(data)
        })

        datas = this.helperService.sortByOrder(datas)
        this.loading = false
        resolve(datas)
      })
    },

    download(type, ressource, rid) {
      this.loading = true
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/fiche-pesee`
      const filename = `evenement_${rid}_fiche_pesee_phytosanitaire`
      this.fileService.downloadFile(endpoint, filename, type)
      this.loading = false
    },

    async setParameters(parameters) {
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          // eslint-disable-next-line no-param-reassign
          parameters[key] = null
        }
      })
      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/phytosanitaire/pesee/${this.fiche_pesee_id}`
      const response = await this.fetchService.put(endpoint, parameters)
      const metas = await response.meta
      if (metas.success) {
        this.setComponent()
        this.modal.params = false
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Les paramètres de la fiche de pesées ont bien été modifiés.',
        })
      }
    },

    async onSubmit(id, values) {
      const params = { ...values }
      params.date = params.date
        ? this.helperService.formatDateForApi(params.date.slice(0, 10))
        : null
      params.trigramme = params.trigramme ? params.trigramme : null
      params.composant_uid = values.composant_uid?.key
      params.unite_uid = values.unite_uid?.key
      params.pesee_reelle = this.taskService.getValueOnly(values.pesee_reelle)
      params.dose_epandue = this.taskService.getValueOnly(values.dose_epandue)

      Object.keys(params).forEach((key) => {
        if (params[key] === '') {
          params[key] = null
        }
      })

      const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/phytosanitaire/pesee/modalite/${id}`
      const response = await this.fetchService.put(endpoint, params)
      if (response.meta.success) {
        this.mods = []
        this.loading = true
        this.setComponent()
        this.modal.edit = false
        this.emitter.emit('alert', {
          type: 'success',
          content: `La pesée ${id} a bien été modifiée.`,
        })
      }
    },

    getUnites() {
      this.fetchService.get('unite?filters=uid:in(GRAMME)').then((ru) => {
        const datas = ru.data
        const result = []
        datas.forEach((data) => {
          result.push({
            label: data.valeur,
            value: data.uid,
          })
        })
        this.unites = result
      })
    },

    getUnitesDoseEpandueEndpoint(...unites) {
      const u = unites.join(',')
      return `unite?filters=uid:in(${u})`
    },
  },
}
</script>
